<template>
  <transition name="fade">
    <v-card
      v-if="isOpen"
      class="survey-questions"
    >
      <template #title>
        Mieux vous connaître en 1 clic
      </template>

      <template #append>
        <v-btn
          size="small"
          flat
          color="primary"
          text="Create goal"
          variant="text"
          slim
          @click="close"
        >
          Fermer
        </v-btn>
      </template>

      <VCardText>
        <p
          class="survey-questions__questions"
          v-html="selectedQuestion.question"
        />

        <div
          v-if="isFetching"
          class="text-center"
        >
          <div class="lds-dual-ring">
          &nbsp;
          </div>
        </div>
      </VCardText>
      <VCardActions>
        <v-btn
          flat
          class="survey-questions__button"
          @click="sendResponse('answer_1_count')"
        >
          {{ selectedQuestion.answer_1 }}
        </v-btn>
        <v-btn
          flat
          class="survey-questions__button"
          @click="sendResponse('answer_2_count')"
        >
          {{ selectedQuestion.answer_2 }}
        </v-btn>
      </VCardActions>
    </v-card>
  </transition>
</template>

<script>
const { api } = useFeathers()

export default {
  data () {
    return {
      questions: [],
      status: 'idle',
      isOpen: false
    }
  },

  computed: {
    questionsFetched () {
      return this.questions.length
    },
    selectedQuestion () {
      if (!this.questionsFetched) {
        return null
      }

      const randomIndex = Math.floor(Math.random() * this.questions.length)
      return this.questions[randomIndex]
    },
    isFetching () {
      return this.status === 'fetching'
    },
    isIdle () {
      return this.status === 'idle'
    },
    isSuccessfull () {
      return this.status === 'success'
    },
    hasError () {
      return this.status === 'error'
    }
  },

  watch: {
    selectedQuestion (val) {
      if (val) {
        this.open()
      }
    }
  },

  async mounted () {
    const dismissed = localStorage.getItem('survey-questions') === 'true'

    if (dismissed) { return }

    const data = await api.service('survey-questions').find({
      query: {
        is_active: 1
      }
    })
    this.questions = data
  },

  methods: {
    open () {
      this.isOpen = true
    },
    close () {
      localStorage.setItem('survey-questions', true)
      this.isOpen = false
    },
    async sendResponse (answerKey) {
      try {
        this.status = 'fetching'

        const question = await api.service('survey-questions').get(`${this.selectedQuestion.id}`)
        const nb = +question[answerKey] || 0

        await await api.service('survey-questions').patch(`${this.selectedQuestion.id}`, {
          [answerKey]: nb + 1
        })

        this.close()
      } catch (e) {
        this.status = 'error'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* SPINNER */
.lds-dual-ring {
  display: inline-block;
  width: 100%;
}

.lds-dual-ring::after {
  content: ' ';
  display: block;
  width: 60px;
  height: 60px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 6px solid $light-blue;
  border-color: $light-blue transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.survey-questions {
  z-index: 4;
  position: absolute;
  bottom: 15px;
  left: 15px;
  min-width: 300px;
  max-width: 500px;
  padding: 15px;
  border-radius: 15px;
  background: $white;
  font-size: 12px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    right: 15px;
    max-width: unset;
  }

  &__close {
    border: 0;
    background: transparent;
    color: $night-blue;
    margin-bottom: 1em;

    &:hover,
    &:focus {
      color:$pink;
    }
  }

  &__title {
    font-size: 14px;
  }

  &__buttons-group {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
  }

  &__button {
    padding: 0.5em;
    flex-grow: 1;
  }

  &__error {
    color: $pink;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

</style>
