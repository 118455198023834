<template>
  <NuxtLoadingIndicator style="position: sticky;" />
  <div class="default">
    <LazyHNavBar />
    <LazyHStickyBar />
    <main>
      <slot />
    </main>
    <LazySectionFooter />
  </div>
  <div class="surveyQuestions">
    <HSurveyQuestions v-if="['production', 'staging'].includes(config.app.awsEnvironment)" />
  </div>
  <div v-if="hydrated">
    <div v-if="showWhiteBook">
      <HWhiteBookAgentsModal />
    </div>
    <div v-else>
      <HWhiteBookModal />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
const config = useRuntimeConfig()

useSeoMeta({
  description: "homeloop s'engage à acheter votre appartement ou maison. Recevez une offre d'achat en 48 heures pour en finir avec les incertitudes.",
  ogDescription: "homeloop s'engage à acheter votre appartement ou maison. Recevez une offre d'achat en 48 heures pour en finir avec les incertitudes.",
  ogImage: config.app.cdnURL + '/img/logo-homeloop.jpg',
  twitterCard: 'summary_large_image'
})

const hydrated = ref(false)
const showWhiteBook = ref(false)

onMounted(() => {
  showWhiteBook.value = Math.random() >= 0.5
  hydrated.value = true
})
</script>

<style lang="scss" scoped>
.surveyQuestions {
  position: sticky;
  height: 0;
  bottom:0;
  z-index: 1
}
</style>
